<template>
  <div class="mt-5 mx-5">
    <v-card>
      <v-card-text class="d-flex flex-row my-3">
        <v-img
          :src="require('@/assets/svg/needs.svg')"
          width="60"
          height="60"
          contain
        />
        <div class="d-flex flex-column mx-3">
          <span style="color: #5b2cae; font-size: 14px;">
            Is Ongoing
          </span>
          <span style="color: #5b2cae; font-size: 18px;">
            Reduce Social in Older Adults - Warm connectors Needed
          </span>
          <span style="color: #5b2cae; font-size: 14px;">
            JFS of Metrowest
          </span>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text class="d-flex flex-row my-3">
        <v-img
          :src="require('@/assets/svg/needs.svg')"
          width="60"
          height="60"
          contain
        />
        <div class="d-flex flex-column mx-3">
          <span style="color: #5b2cae; font-size: 14px;">
            Is Ongoing
          </span>
          <span style="color: #5b2cae; font-size: 18px;">
            Reduce Social in Older Adults - Warm connectors Needed
          </span>
          <span style="color: #5b2cae; font-size: 14px;">
            JFS of Metrowest
          </span>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text class="d-flex flex-row my-3">
        <v-img
          :src="require('@/assets/svg/needs.svg')"
          width="60"
          height="60"
          contain
        />
        <div class="d-flex flex-column mx-3">
          <span style="color: #5b2cae; font-size: 14px;">
            Is Ongoing
          </span>
          <span style="color: #5b2cae; font-size: 18px;">
            Reduce Social in Older Adults - Warm connectors Needed
          </span>
          <span style="color: #5b2cae; font-size: 14px;">
            JFS of Metrowest
          </span>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text class="d-flex flex-row my-3">
        <v-img
          :src="require('@/assets/svg/needs.svg')"
          width="60"
          height="60"
          contain
        />
        <div class="d-flex flex-column mx-3">
          <span style="color: #5b2cae; font-size: 14px;">
            Is Ongoing
          </span>
          <span style="color: #5b2cae; font-size: 18px;">
            Reduce Social in Older Adults - Warm connectors Needed
          </span>
          <span style="color: #5b2cae; font-size: 14px;">
            JFS of Metrowest
          </span>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text class="d-flex flex-row my-3">
        <v-img
          :src="require('@/assets/svg/needs.svg')"
          width="60"
          height="60"
          contain
        />
        <div class="d-flex flex-column mx-3">
          <span style="color: #5b2cae; font-size: 14px;">
            Is Ongoing
          </span>
          <span style="color: #5b2cae; font-size: 18px;">
            Reduce Social in Older Adults - Warm connectors Needed
          </span>
          <span style="color: #5b2cae; font-size: 14px;">
            JFS of Metrowest
          </span>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text class="d-flex flex-row my-3">
        <v-img
          :src="require('@/assets/svg/needs.svg')"
          width="60"
          height="60"
          contain
        />
        <div class="d-flex flex-column mx-3">
          <span style="color: #5b2cae; font-size: 14px;">
            Is Ongoing
          </span>
          <span style="color: #5b2cae; font-size: 18px;">
            Reduce Social in Older Adults - Warm connectors Needed
          </span>
          <span style="color: #5b2cae; font-size: 14px;">
            JFS of Metrowest
          </span>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text class="d-flex flex-row my-3">
        <v-img
          :src="require('@/assets/svg/needs.svg')"
          width="60"
          height="60"
          contain
        />
        <div class="d-flex flex-column mx-3">
          <span style="color: #5b2cae; font-size: 14px;">
            Is Ongoing
          </span>
          <span style="color: #5b2cae; font-size: 18px;">
            Reduce Social in Older Adults - Warm connectors Needed
          </span>
          <span style="color: #5b2cae; font-size: 14px;">
            JFS of Metrowest
          </span>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {};
</script>
